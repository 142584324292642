.containerInvites{
    margin: 20px 0 20px 0;
    padding: 30px 20px 30px 20px;
    border-width: 1px;
    border-color: #d0cdcd;
    border-style: solid;
    border-radius: 15px;
}
.alignContentMiddleHorizontal{
    display: flex;
    justify-content: center;
}
.half-width{
    width: 50%;
}
.titleStyle{
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0 30px 0;
    text-align: center;
}
